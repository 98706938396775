import * as React from 'react';

import { signIn } from 'next-auth/react';

import { Button, ButtonLeadIcon } from '@axiom-ui/react/button';
import { GoogleIcon } from '@axiom-ui/react/icons';
import { Separator } from '@axiom-ui/react/separator';
import { Heading } from '@axiom-ui/react/typography';

import { isReactWebView } from '@/shared/utils/is-react-webview';

type GoogleSignInButtonProps = {
  isLoading: boolean;
};

const isWebView = isReactWebView();

async function loginFormSigninGoogle() {
  await signIn('google', {
    callbackUrl: `/v2/login-redirect/`,
  });
}

export default function PageFormSigninGoogleButton({
  isLoading,
}: GoogleSignInButtonProps) {
  if (isWebView) {
    return null;
  }

  return (
    <>
      <div className="flex w-full items-center justify-center gap-2">
        <Separator />
        <Heading variant="overline" className="text-smooth">
          ou
        </Heading>
        <Separator />
      </div>
      <Button
        onClick={() => loginFormSigninGoogle()}
        loading={isLoading}
        loadingText="Entrar com Google"
        disabled={isLoading}
        size="lg"
        variant="outline"
      >
        <ButtonLeadIcon>
          <GoogleIcon />
        </ButtonLeadIcon>
        Entrar com Google
      </Button>
    </>
  );
}
