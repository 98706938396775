import { useEffect, useState } from 'react';

export function useHydrationReady() {
  const [isHydrationReady, setIsHydrationReady] = useState(false);

  useEffect(() => {
    setIsHydrationReady(true);
  }, []);

  return isHydrationReady;
}
