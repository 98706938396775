import { useMutation } from '@tanstack/react-query';
import {
  ERROR_MESSAGES_CODE,
  ERROR_MESSAGES_MAP,
  type ErrorMessagesCodeType,
} from 'auth/types';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { clientEnv } from '@/env/client.mjs';

async function login({
  email,
  password,
  nextUrl,
}: {
  email: string;
  password: string;
  nextUrl: string;
}) {
  const isMobile = !!window.mobileWebview;
  const result = await signIn('credentials', {
    email,
    password,
    nextUrl,
    isMobile,
    ...(window.mobileWebview && {
      platform: window.mobileWebview.Constants.platform,
      installationId: window.mobileWebview.Constants.installationId,
    }),
    callbackUrl: `${clientEnv.NEXT_PUBLIC_BASE_URL}/v2/login-redirect/`,
    redirect: false,
  });

  if (result?.error) {
    throw new Error(result.error);
  }
}

export function useLoginEmailPassword() {
  const router = useRouter();

  const { mutate, error, isPending } = useMutation({
    mutationFn: login,
    onError: (error) => {
      if (error.message === ERROR_MESSAGES_CODE.PasswordExpired) {
        window.location.href = '/new/usuarios/senha-expirada/';
      }
    },
    onSuccess: () => {
      router.push('/login-redirect/');
    },
  });

  const customErrorMessage = ERROR_MESSAGES_MAP.get(
    (error?.message as ErrorMessagesCodeType) ??
      ERROR_MESSAGES_CODE.GenericError,
  );

  return {
    isLoading: isPending,
    error: error?.message ? customErrorMessage : null,
    mutate,
  };
}
