export function Logo(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="148"
      height="32"
      viewBox="0 0 148 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_290_1756)">
        <path
          d="M18.8062 2.51041C17.7528 2.51041 17.0955 2.94219 16.4822 3.75902C12.3568 9.22925 0 25.6207 0 25.6207C0 25.6207 2.42846 25.6207 3.23704 25.6207C5.12645 25.6207 6.40806 24.9084 8.70177 23.2802C10.6077 21.9271 12.8574 20.1835 14.7605 18.9899C17.2798 17.4112 19.0042 17.0344 19.942 18.4563C22.5767 22.4469 22.8683 23.261 24.4964 23.261C25.1042 23.261 26.9249 23.261 26.9249 23.261L20.9568 2.51041C20.9568 2.51041 19.3452 2.51041 18.8062 2.51041ZM19.799 16.6137C18.2479 15.7721 16.2952 16.2424 13.9547 17.4002C11.6005 18.5663 8.85028 20.431 5.7205 22.3644L18.8062 5.04338L23.058 19.9937C21.7654 18.5278 20.6323 17.0647 19.799 16.6137Z"
          fill="#D40054"
        />
        <path
          d="M37.5106 9.06974H33.4512C33.4512 9.06974 33.608 8.00264 33.6602 7.64786C33.7977 6.72378 34.081 6.03897 34.51 5.60168C34.9391 5.16164 35.6266 4.94162 36.5755 4.94162C36.9578 4.94162 38.1019 4.94162 38.1019 4.94162L39.323 2.51316C39.323 2.51316 37.3923 2.51316 36.7487 2.51316C34.8978 2.51316 33.509 2.91195 32.5849 3.70952C31.658 4.50709 31.0805 5.8327 30.8165 7.68362C30.767 8.03015 30.6075 9.07249 30.6075 9.07249H28.179L27.8325 11.3277H30.3297L28.663 23.2637H31.4738L33.1404 11.3277H37.1998L37.5106 9.06974Z"
          fill="#D40054"
        />
        <path
          d="M51.6688 9.06974C50.9757 9.06974 50.3267 9.48502 50.0022 10.1808L44.6942 21.1103L41.8147 9.06974H38.9682L42.5435 23.1592C42.637 23.5058 42.681 23.8633 42.681 24.2346C42.681 24.8369 42.571 25.4089 42.2135 25.9535C41.8532 26.498 41.3526 26.9711 40.7036 27.3066C40.0545 27.6421 39.246 27.7741 38.4126 27.7741H37.1118L35.8604 30.2026H38.4814C39.8703 30.2026 40.9566 30.0238 41.7432 29.6636C42.5298 29.3033 43.1761 28.7725 43.6876 28.0684C44.1964 27.3616 44.8317 26.256 45.5963 24.7544C47.5957 20.8298 53.6132 9.06974 53.6132 9.06974H51.6688Z"
          fill="#D40054"
        />
        <path
          d="M60.7969 9.06974H55.973L54.8976 11.3249H60.3459C61.3167 11.3249 62.029 11.5037 62.48 11.864C62.9311 12.2243 63.1566 12.8293 63.1566 13.6846C63.1566 13.8222 63.1098 14.3722 63.0768 14.5867C61.9767 14.5867 58.0466 14.5867 58.0466 14.5867C56.567 14.5867 55.3459 14.9002 54.3861 15.5246C53.4262 16.1489 52.8542 17.1554 52.6672 18.5443C52.6204 18.9156 52.5984 19.1686 52.5984 19.3089C52.5984 20.5823 53.0275 21.6218 53.8828 22.4332C54.7381 23.2445 55.8382 23.6488 57.1803 23.6488C59.0065 23.6488 60.8189 22.7934 62.3233 21.08C62.3233 21.08 62.3233 21.08 62.3233 21.0773L62.425 23.2637H64.6802C64.6802 23.2637 65.5108 17.2765 65.7913 15.2688C65.8601 14.7627 65.9646 14.0257 65.9646 13.3739C65.9674 10.5054 64.2429 9.06974 60.7969 9.06974ZM59.9553 20.6758C59.144 21.058 58.4042 21.2478 57.7359 21.2478C57.0868 21.2478 56.5258 21.069 56.0527 20.7088C55.5769 20.3512 55.3404 19.8479 55.3404 19.1989C55.3404 18.3656 55.5879 17.766 56.0857 17.3947C56.5835 17.0262 57.2243 16.8392 58.0109 16.8392H62.7743L62.4608 19.0944C61.699 19.7682 60.7639 20.2962 59.9553 20.6758Z"
          fill="#D40054"
        />
        <path
          d="M76.8716 23.7176H73.8243L75.689 13.1677H78.7363L76.8716 23.7176ZM78.9975 11.8256H75.8678L76.3298 9.17707H79.4596L78.9975 11.8256Z"
          fill="#1A1A1A"
        />
        <path
          d="M88.6234 9.47959C90.4275 9.47959 92.2345 9.80137 93.197 10.2222L92.1932 12.6286C91.2499 12.3481 90.1278 12.2078 88.9039 12.2078C85.6751 12.2078 84.3303 13.3519 83.7307 16.699C83.6097 17.3205 83.5712 17.8623 83.5712 18.3436C83.5712 20.3485 84.575 21.1929 87.3418 21.1929C88.5656 21.1929 89.7895 21.0334 90.8318 20.7721L90.9721 23.1785C89.8885 23.5801 88.2054 23.9211 86.3984 23.9211C82.5069 23.9211 80.2021 22.3562 80.2021 18.7479C80.2021 18.1071 80.2626 17.425 80.4029 16.6632C81.283 11.7265 83.8297 9.47959 88.6234 9.47959Z"
          fill="#1A1A1A"
        />
        <path
          d="M95.5403 9.68036H98.7883L96.7614 21.0719H103.519L103.057 23.7176H93.0678L95.5403 9.68036Z"
          fill="#1A1A1A"
        />
        <path
          d="M107.754 9.68036H111.002L108.535 23.7176H105.287L107.754 9.68036Z"
          fill="#1A1A1A"
        />
        <path
          d="M124.159 23.7176H120.369L117.622 17.139C117.099 15.8959 116.72 14.8536 116.398 13.4894H116.338C116.217 14.9938 116.038 16.2369 115.777 17.7221L114.712 23.7176H111.582L114.049 9.68036H117.839L120.545 16.2589C121.068 17.502 121.448 18.5444 121.769 19.9085H121.83C121.951 18.4041 122.152 17.161 122.413 15.6759L123.477 9.68036H126.626L124.159 23.7176Z"
          fill="#1A1A1A"
        />
        <path
          d="M129.652 9.68036H132.9L130.433 23.7176H127.185L129.652 9.68036Z"
          fill="#1A1A1A"
        />
        <path
          d="M142.426 9.47959C144.231 9.47959 146.037 9.80137 147 10.2222L145.996 12.6286C145.053 12.3481 143.931 12.2078 142.707 12.2078C139.478 12.2078 138.133 13.3519 137.534 16.699C137.413 17.3205 137.374 17.8623 137.374 18.3436C137.374 20.3485 138.378 21.1929 141.145 21.1929C142.369 21.1929 143.592 21.0334 144.635 20.7721L144.775 23.1785C143.691 23.5801 142.008 23.9211 140.201 23.9211C136.31 23.9211 134.005 22.3562 134.005 18.7479C134.005 18.1071 134.066 17.425 134.206 16.6632C135.086 11.7265 137.633 9.47959 142.426 9.47959Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_290_1756">
          <rect
            width="148"
            height="29.4668"
            fill="white"
            transform="translate(0 1.26659)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
