import { ERROR_MESSAGES_MAP, type ErrorMessagesCodeType } from 'auth/types';
import { useSearchParams } from 'next/navigation';

function getErrorMessageFromAuthCallback(key: ErrorMessagesCodeType) {
  return ERROR_MESSAGES_MAP.get(key);
}

export function useGetErrorMessageCallback() {
  const searchParams = useSearchParams();
  const errorQueryParams = searchParams.get('error') as ErrorMessagesCodeType;

  return errorQueryParams && getErrorMessageFromAuthCallback(errorQueryParams);
}
